




















import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IRelease } from '@/interfaces';
import { readAdminReleases } from '@/store/admin/getters';
import { dispatchGetReleases } from '@/store/admin/actions';

@Component
export default class AdminReleases extends Vue {
  public headers = [
    {
      text: 'Version',
      sortable: true,
      value: 'version_string',
      align: 'left',
    },
    {
      text: 'Product',
      sortable: true,
      value: 'product',
      align: 'left',
    },
    {
      text: 'Date',
      sortable: true,
      value: 'date',
      align: 'left',
    },
    {
      text: 'Download',
      sortable: true,
      value: 'download_url',
      align: 'left',
    },
  ];
  get releases() {
    return readAdminReleases(this.$store);
  }

  public async mounted() {
    await dispatchGetReleases(this.$store);
  }
}
